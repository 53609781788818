/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { CreateClientAsAdmin200ResponseFromJSON, GetHealth200ResponseFromJSON, SetDatabaseChangeRequestToJSON, } from '../models/index';
/**
 *
 */
export class OtherApi extends runtime.BaseAPI {
    /**
     * get api health status
     */
    getHealthRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/health`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetHealth200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * get api health status
     */
    getHealth(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getHealthRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Notify backend on data change, called via hasura event webhook
     */
    setDatabaseChangeRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/ioe/data-change`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SetDatabaseChangeRequestToJSON(requestParameters['setDatabaseChangeRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Notify backend on data change, called via hasura event webhook
     */
    setDatabaseChange(setDatabaseChangeRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.setDatabaseChangeRaw({ setDatabaseChangeRequest: setDatabaseChangeRequest }, initOverrides);
            return yield response.value();
        });
    }
}
