/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AddClientTeamMemberRequest interface.
 */
export function instanceOfAddClientTeamMemberRequest(value) {
    return true;
}
export function AddClientTeamMemberRequestFromJSON(json) {
    return AddClientTeamMemberRequestFromJSONTyped(json, false);
}
export function AddClientTeamMemberRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'email': json['email'] == null ? undefined : json['email'],
        'firstname': json['firstname'] == null ? undefined : json['firstname'],
        'lastname': json['lastname'] == null ? undefined : json['lastname'],
    };
}
export function AddClientTeamMemberRequestToJSON(json) {
    return AddClientTeamMemberRequestToJSONTyped(json, false);
}
export function AddClientTeamMemberRequestToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'email': value['email'],
        'firstname': value['firstname'],
        'lastname': value['lastname'],
    };
}
