import axiosRetry from 'axios-retry'
import axios from 'axios'
import { defineNuxtPlugin, type NuxtApp } from 'nuxt/app'
import * as saasApi from '@sec-auditor/saas-api-sdk'
import * as apiSdk from '@sec-auditor/api-sdk'
import type { FetchAPI, RequestContext } from '@sec-auditor/api-sdk'

const createFetchWithRetry = (baseFetch: FetchAPI = fetch): FetchAPI => {
  return async (url: string, init: RequestInit): Promise<Response> => {
    const maxRetries = 3
    let retries = 0
    let lastError: unknown

    while (retries < maxRetries) {
      try {
        const response = await baseFetch(url, init)

        // If we get a 5xx response, treat it as retryable
        if (response.status >= 500 && response.status < 600) {
          lastError = response
          retries++

          // Exponential backoff
          await new Promise(resolve => setTimeout(resolve, Math.pow(2, retries) * 200))
          continue
        }

        // Return successful response or non-5xx errors
        return response
      } catch (error) {
        lastError = error
        retries++

        // Only retry on network errors
        if (!(error instanceof TypeError)) {
          throw error
        }

        // Exponential backoff for network errors
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, retries) * 200))
      }
    }

    // If we've exhausted retries, throw the last error we encountered
    if (lastError instanceof Response) {
      return lastError // Return the last error response
    }

    throw lastError // Throw the last error if it wasn't a Response object
  }
}

export default defineNuxtPlugin(async (nuxtApp: NuxtApp) => {
  try {
    const { data: session } = useAuth()
    const envVars = useRuntimeConfig()

    let accessToken = session?.value?.accessToken

    // if these are not provided, we need to sign in
    if (!accessToken) {
      const headers = useRequestHeaders(['cookie']) as HeadersInit
      const { data: newToken } = await useFetch('/api/auth/session', { headers })

      if (newToken.value) {
        accessToken = newToken.value?.accessToken
      }
    }

    // axios default setup
    const axiosInstance = axios.create({
      headers: {
        common: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    })

    axiosInstance.defaults.withCredentials = true

    // retry on 5xx response codes
    axiosRetry(axiosInstance, {
      retries: 3,
      retryDelay: axiosRetry.exponentialDelay
    })

    // refresh token if backend returns 401 with delay
    // . only request 3 times
    // from: https://github.com/axios/axios/issues/934
    let count = 0
    axiosInstance.interceptors.response.use(null, async (error) => {
      if (
        error.config &&
        error.response &&
        error.response.status === 401 &&
        count < 3
      ) {
        count++

        const headers = useRequestHeaders(['cookie']) as HeadersInit
        const { data: newSession } = await useFetch('/api/auth/session', {
          headers
        })

        accessToken = newSession.value?.accessToken

        error.config.headers.Authorization = `Bearer ${newSession.value?.accessToken}`
        return axiosInstance.request(error.config)
      }

      count = 0

      // handle error in axios-retry
      return Promise.reject(error)
    })

    // Create middleware for refreshing tokens
    const authMiddleware = async (context: RequestContext): Promise<Response> => {
      // Try the request
      try {
        const response = await context.fetch(context.url, context.init)

        // If successful, return it
        if (response.ok) {
          return response
        }

        // Handle 401 errors
        if (response.status === 401) {
          const headers = useRequestHeaders(['cookie']) as HeadersInit
          const { data: newSession } = await useFetch('/api/auth/session', { headers })
          accessToken = newSession.value?.accessToken

          // Update the Authorization header
          context.init.headers = {
            ...context.init.headers as Record<string, string>,
            Authorization: `Bearer ${accessToken}`
          }

          // Retry the request
          return await context.fetch(context.url, context.init)
        }

        return response
      } catch (error) {
        // Let fetch errors propagate
        throw error
      }
    }

    const fetchWithRetry = createFetchWithRetry()
    const configuration = new apiSdk.Configuration({
      basePath: envVars.public.API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      middleware: [authMiddleware],
      fetchApi: fetchWithRetry
    })

    const client = new apiSdk.ClientApi(configuration)
    const serviceprovider = new apiSdk.ServiceproviderApi(configuration)
    const other = new apiSdk.OtherApi(configuration)
    const user = new apiSdk.UserApi(configuration)
    const admin = new apiSdk.AdminApi(configuration)

    const saas = {
      billing: new saasApi.BillingApi(undefined, envVars.public.SAAS_API_URL, axiosInstance),
      other: new saasApi.OtherApi(undefined, envVars.public.SAAS_API_URL, axiosInstance),
      user: new saasApi.UserApi(undefined, envVars.public.SAAS_API_URL, axiosInstance)
    }

    // Inject to context as $api
    const api = { client, serviceprovider, other, user, admin }
    return {
      provide: {
        api,
        saas,
        axiosInstance
      }
    }
  } catch (e) {
    nuxtApp.$logger.error(e)
  }
})
