/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetEntraMeta200Response interface.
 */
export function instanceOfGetEntraMeta200Response(value) {
    return true;
}
export function GetEntraMeta200ResponseFromJSON(json) {
    return GetEntraMeta200ResponseFromJSONTyped(json, false);
}
export function GetEntraMeta200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'applicationId': json['applicationId'] == null ? undefined : json['applicationId'],
        'tenantId': json['tenantId'] == null ? undefined : json['tenantId'],
    };
}
export function GetEntraMeta200ResponseToJSON(json) {
    return GetEntraMeta200ResponseToJSONTyped(json, false);
}
export function GetEntraMeta200ResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'applicationId': value['applicationId'],
        'tenantId': value['tenantId'],
    };
}
