/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SetDatabaseChangeRequestTable interface.
 */
export function instanceOfSetDatabaseChangeRequestTable(value) {
    return true;
}
export function SetDatabaseChangeRequestTableFromJSON(json) {
    return SetDatabaseChangeRequestTableFromJSONTyped(json, false);
}
export function SetDatabaseChangeRequestTableFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'] == null ? undefined : json['name'],
        'schema': json['schema'] == null ? undefined : json['schema'],
    };
}
export function SetDatabaseChangeRequestTableToJSON(json) {
    return SetDatabaseChangeRequestTableToJSONTyped(json, false);
}
export function SetDatabaseChangeRequestTableToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'schema': value['schema'],
    };
}
