import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app'
import { useCurrentClient } from '~/composables/currentClient'
import { useCurrentServiceprovider } from '~/composables/serviceprovider'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $logger, $toast } = useNuxtApp()
  const { initializeServiceprovider } = useCurrentServiceprovider()
  const { initializeCurrentClient } = useCurrentClient()
  const { mainRole, userOrganization } = useCurrentUserSession()

  const emptyPathes = ['', '/', '/index', '/index/',
    '/provider', '/provider/', '/provider/index', '/provider/index/',
    '/client', '/client/', `/client/${userOrganization}/`, '/client/index', '/client/index/', `/provider/${userOrganization}/`]

  // / main route
  if (emptyPathes.includes(to.path)) {
    try {
      if (mainRole === 'serviceprovider') {
        initializeServiceprovider(userOrganization).catch((error) => {
          $logger.error(error)
        })
        return navigateTo(`/provider/${userOrganization}/monitoring`)
      } else if (mainRole === 'client') {
        // fetch client meta data
        initializeCurrentClient(userOrganization).catch((error) => {
          $logger.error(error)
        })
        return navigateTo(`/client/${userOrganization}/dashboard`)
      } else if (mainRole === 'admin') {
        return navigateTo('/admin/serviceprovider')
      }
    } catch (error) {
      $logger.error(error)
      $toast.error('Fehler: Bitte melde dich erneut an')
    }
  }
})
