/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CreateClientAsAdmin200Response interface.
 */
export function instanceOfCreateClientAsAdmin200Response(value) {
    if (!('message' in value) || value['message'] === undefined)
        return false;
    return true;
}
export function CreateClientAsAdmin200ResponseFromJSON(json) {
    return CreateClientAsAdmin200ResponseFromJSONTyped(json, false);
}
export function CreateClientAsAdmin200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'message': json['message'],
    };
}
export function CreateClientAsAdmin200ResponseToJSON(json) {
    return CreateClientAsAdmin200ResponseToJSONTyped(json, false);
}
export function CreateClientAsAdmin200ResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'message': value['message'],
    };
}
