/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { GetPasswordChangeLink200ResponseFromJSON, GetUser200ResponseFromJSON, GetUser200ResponseToJSON, ValidateSession200ResponseFromJSON, } from '../models/index';
/**
 *
 */
export class UserApi extends runtime.BaseAPI {
    /**
     * End user Keycloak user session
     */
    endKeycloakSessionRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/user/keycloak/end-session`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * End user Keycloak user session
     */
    endKeycloakSession(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.endKeycloakSessionRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Keycloak password change link
     */
    getPasswordChangeLinkRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/user/password/change`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetPasswordChangeLink200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Get Keycloak password change link
     */
    getPasswordChangeLink(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getPasswordChangeLinkRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * update email subscription user
     */
    getSubscriptionUpdateEmailRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/user/email-subscription/update`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * update email subscription user
     */
    getSubscriptionUpdateEmail(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.getSubscriptionUpdateEmailRaw(initOverrides);
        });
    }
    /**
     * Get Keycloak user
     */
    getUserRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/user/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetUser200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Get Keycloak user
     */
    getUser(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getUserRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Keycloak webhooks
     */
    handleUserWebhooksRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/user/webhook`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     * Get Keycloak webhooks
     */
    handleUserWebhooks(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.handleUserWebhooksRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update user
     */
    updateUserRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['getUser200Response'] == null) {
                throw new runtime.RequiredError('getUser200Response', 'Required parameter "getUser200Response" was null or undefined when calling updateUser().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/user/`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: GetUser200ResponseToJSON(requestParameters['getUser200Response']),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Update user
     */
    updateUser(getUser200Response, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.updateUserRaw({ getUser200Response: getUser200Response }, initOverrides);
        });
    }
    /**
     * Validate user session
     */
    validateSessionRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/user/session/validate`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ValidateSession200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Validate user session
     */
    validateSession(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.validateSessionRaw(initOverrides);
            return yield response.value();
        });
    }
}
