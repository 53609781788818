/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetUser200ResponseNotifications interface.
 */
export function instanceOfGetUser200ResponseNotifications(value) {
    return true;
}
export function GetUser200ResponseNotificationsFromJSON(json) {
    return GetUser200ResponseNotificationsFromJSONTyped(json, false);
}
export function GetUser200ResponseNotificationsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'alert': json['alert'] == null ? undefined : json['alert'],
        'quickcheck': json['quickcheck'] == null ? undefined : json['quickcheck'],
        'info': json['info'] == null ? undefined : json['info'],
    };
}
export function GetUser200ResponseNotificationsToJSON(json) {
    return GetUser200ResponseNotificationsToJSONTyped(json, false);
}
export function GetUser200ResponseNotificationsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'alert': value['alert'],
        'quickcheck': value['quickcheck'],
        'info': value['info'],
    };
}
