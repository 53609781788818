/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetAllIoes200ResponseIoeInnerFromJSON, GetAllIoes200ResponseIoeInnerToJSON, } from './GetAllIoes200ResponseIoeInner';
/**
 * Check if a given object implements the GetAllIoes200Response interface.
 */
export function instanceOfGetAllIoes200Response(value) {
    return true;
}
export function GetAllIoes200ResponseFromJSON(json) {
    return GetAllIoes200ResponseFromJSONTyped(json, false);
}
export function GetAllIoes200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'ioe': json['ioe'] == null ? undefined : (json['ioe'].map(GetAllIoes200ResponseIoeInnerFromJSON)),
    };
}
export function GetAllIoes200ResponseToJSON(json) {
    return GetAllIoes200ResponseToJSONTyped(json, false);
}
export function GetAllIoes200ResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'ioe': value['ioe'] == null ? undefined : (value['ioe'].map(GetAllIoes200ResponseIoeInnerToJSON)),
    };
}
