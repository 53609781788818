/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the UpdateServiceproviderRequest interface.
 */
export function instanceOfUpdateServiceproviderRequest(value) {
    if (!('companyName' in value) || value['companyName'] === undefined)
        return false;
    return true;
}
export function UpdateServiceproviderRequestFromJSON(json) {
    return UpdateServiceproviderRequestFromJSONTyped(json, false);
}
export function UpdateServiceproviderRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'companyName': json['companyName'],
        'email': json['email'] == null ? undefined : json['email'],
        'phone': json['phone'] == null ? undefined : json['phone'],
    };
}
export function UpdateServiceproviderRequestToJSON(json) {
    return UpdateServiceproviderRequestToJSONTyped(json, false);
}
export function UpdateServiceproviderRequestToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'companyName': value['companyName'],
        'email': value['email'],
        'phone': value['phone'],
    };
}
