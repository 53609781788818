/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateServiceproviderRequestOtherFromJSON, CreateServiceproviderRequestOtherToJSON, } from './CreateServiceproviderRequestOther';
/**
 * Check if a given object implements the CreateServiceproviderRequest interface.
 */
export function instanceOfCreateServiceproviderRequest(value) {
    if (!('initialAccountEmail' in value) || value['initialAccountEmail'] === undefined)
        return false;
    if (!('initialAccountFirstname' in value) || value['initialAccountFirstname'] === undefined)
        return false;
    if (!('initialAccountLastname' in value) || value['initialAccountLastname'] === undefined)
        return false;
    if (!('companyName' in value) || value['companyName'] === undefined)
        return false;
    return true;
}
export function CreateServiceproviderRequestFromJSON(json) {
    return CreateServiceproviderRequestFromJSONTyped(json, false);
}
export function CreateServiceproviderRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'initialAccountEmail': json['initialAccountEmail'],
        'initialAccountFirstname': json['initialAccountFirstname'],
        'initialAccountLastname': json['initialAccountLastname'],
        'companyName': json['companyName'],
        'other': json['other'] == null ? undefined : CreateServiceproviderRequestOtherFromJSON(json['other']),
    };
}
export function CreateServiceproviderRequestToJSON(json) {
    return CreateServiceproviderRequestToJSONTyped(json, false);
}
export function CreateServiceproviderRequestToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'initialAccountEmail': value['initialAccountEmail'],
        'initialAccountFirstname': value['initialAccountFirstname'],
        'initialAccountLastname': value['initialAccountLastname'],
        'companyName': value['companyName'],
        'other': CreateServiceproviderRequestOtherToJSON(value['other']),
    };
}
