/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AddServiceproviderTeamMember201Response interface.
 */
export function instanceOfAddServiceproviderTeamMember201Response(value) {
    return true;
}
export function AddServiceproviderTeamMember201ResponseFromJSON(json) {
    return AddServiceproviderTeamMember201ResponseFromJSONTyped(json, false);
}
export function AddServiceproviderTeamMember201ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
    };
}
export function AddServiceproviderTeamMember201ResponseToJSON(json) {
    return AddServiceproviderTeamMember201ResponseToJSONTyped(json, false);
}
export function AddServiceproviderTeamMember201ResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
    };
}
