/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CreateServiceproviderRequestOther interface.
 */
export function instanceOfCreateServiceproviderRequestOther(value) {
    return true;
}
export function CreateServiceproviderRequestOtherFromJSON(json) {
    return CreateServiceproviderRequestOtherFromJSONTyped(json, false);
}
export function CreateServiceproviderRequestOtherFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'hEmail': json['hEmail'] == null ? undefined : json['hEmail'],
        't': json['t'] == null ? undefined : json['t'],
    };
}
export function CreateServiceproviderRequestOtherToJSON(json) {
    return CreateServiceproviderRequestOtherToJSONTyped(json, false);
}
export function CreateServiceproviderRequestOtherToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'hEmail': value['hEmail'],
        't': value['t'],
    };
}
