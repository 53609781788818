/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetClientTeam200ResponseInner interface.
 */
export function instanceOfGetClientTeam200ResponseInner(value) {
    return true;
}
export function GetClientTeam200ResponseInnerFromJSON(json) {
    return GetClientTeam200ResponseInnerFromJSONTyped(json, false);
}
export function GetClientTeam200ResponseInnerFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'] == null ? undefined : json['email'],
        'requiredActions': json['requiredActions'] == null ? undefined : json['requiredActions'],
    };
}
export function GetClientTeam200ResponseInnerToJSON(json) {
    return GetClientTeam200ResponseInnerToJSONTyped(json, false);
}
export function GetClientTeam200ResponseInnerToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'requiredActions': value['requiredActions'],
    };
}
