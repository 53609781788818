/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CreateServiceprovider201Response interface.
 */
export function instanceOfCreateServiceprovider201Response(value) {
    if (!('message' in value) || value['message'] === undefined)
        return false;
    return true;
}
export function CreateServiceprovider201ResponseFromJSON(json) {
    return CreateServiceprovider201ResponseFromJSONTyped(json, false);
}
export function CreateServiceprovider201ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'message': json['message'],
        'uuid': json['uuid'] == null ? undefined : json['uuid'],
    };
}
export function CreateServiceprovider201ResponseToJSON(json) {
    return CreateServiceprovider201ResponseToJSONTyped(json, false);
}
export function CreateServiceprovider201ResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'message': value['message'],
        'uuid': value['uuid'],
    };
}
