/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetInstallerLicense200Response interface.
 */
export function instanceOfGetInstallerLicense200Response(value) {
    return true;
}
export function GetInstallerLicense200ResponseFromJSON(json) {
    return GetInstallerLicense200ResponseFromJSONTyped(json, false);
}
export function GetInstallerLicense200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'subscriptionStatus': json['subscriptionStatus'] == null ? undefined : json['subscriptionStatus'],
        'mode': json['mode'] == null ? undefined : json['mode'],
        'sleepForMs': json['sleepForMs'] == null ? undefined : json['sleepForMs'],
    };
}
export function GetInstallerLicense200ResponseToJSON(json) {
    return GetInstallerLicense200ResponseToJSONTyped(json, false);
}
export function GetInstallerLicense200ResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'subscriptionStatus': value['subscriptionStatus'],
        'mode': value['mode'],
        'sleepForMs': value['sleepForMs'],
    };
}
