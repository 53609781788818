/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const CheckUpdate200ResponseStatusEnum = {
    Ok: 'ok',
    UpdateAvailable: 'update_available'
};
/**
 * Check if a given object implements the CheckUpdate200Response interface.
 */
export function instanceOfCheckUpdate200Response(value) {
    return true;
}
export function CheckUpdate200ResponseFromJSON(json) {
    return CheckUpdate200ResponseFromJSONTyped(json, false);
}
export function CheckUpdate200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'status': json['status'] == null ? undefined : json['status'],
        'newestVersion': json['newestVersion'] == null ? undefined : json['newestVersion'],
        'path': json['path'] == null ? undefined : json['path'],
    };
}
export function CheckUpdate200ResponseToJSON(json) {
    return CheckUpdate200ResponseToJSONTyped(json, false);
}
export function CheckUpdate200ResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'status': value['status'],
        'newestVersion': value['newestVersion'],
        'path': value['path'],
    };
}
