import type { AxiosInstance } from 'axios'
import type { ServiceproviderCard } from '~/types/admin.d'

export async function getBufferPictureToBase64 (
  axios: AxiosInstance,
  url: string
): Promise<string> {
  const res = await axios.get(url, {
    responseType: 'arraybuffer'
  })
  // Convert the ArrayBuffer to a binary string
  const binary = Array.from(new Uint8Array(res.data)).map(byte =>
    String.fromCharCode(byte)
  ).join('')
  // Encode the binary string to base64
  const base64 = btoa(binary)
  return 'data:' + res.headers['content-type'] + ';base64,' + base64
}

export function blobToFile (theBlob: any, fileName: string): File {
  return new File([theBlob], fileName, {
    type: theBlob.type
  })
}

export function securityScoreToColor (score: number, asTextClass = false): string {
  let color

  if (score >= 85) {
    color = 'special-green-text'
  } else if (score >= 70) {
    color = 'special-yellow-text'
  } else if (score >= 0) {
    color = 'special-red-text'
  } else {
    color = 'secondary-dark'
  }
  if (asTextClass) {
    return 'text-' + color
  }

  return 'bg-' + color
}

export function createEnrichedProjectCard (rawData: any[], useStripeCustomerAsActiveIndicator = false): ServiceproviderCard[] {
  return rawData.map((item) => {
    return {
      id: item?.client_id_pk || item?.serviceprovider_id_pk,
      type: item?.client_id_pk ? 'client' : 'serviceprovider',
      title: item?.client_name || item?.serviceprovider_name,
      clientAddress: item?.client_address || item?.serviceprovider_address,
      initials: item?.client_name?.substring(0, 2) || item?.serviceprovider_name?.substring(0, 2),
      securityScore: item?.client_security_score || -1,
      bgColor: useStripeCustomerAsActiveIndicator && !item?.serviceprovider_stripe_customer_id ? 'bg-secondary-dark' : 'bg-red-light',
      subscriptionEnd: item?.client_subscription_end,
      employeeCount: item?.client_reported_employee_count,
      cycle: item?.client_billing_cycle
    }
  })
}

// removes all extra roles to return one of the three main roles
// client, serviceprovider or admin
export function getMainRole (roles: string[] | undefined): string {
  if (!roles) {
    return ''
  }

  const rolesCopy = [...roles]

  // remove allow_next_usage
  const nextUserIndex = rolesCopy.indexOf('next_user')

  if (nextUserIndex > -1) {
    rolesCopy.splice(nextUserIndex, 1)
  }

  // remove allow_demo_age
  const demoUserIndex = rolesCopy.indexOf('demo_user')

  if (demoUserIndex > -1) {
    rolesCopy.splice(demoUserIndex, 1)
  }

  return rolesCopy?.[0] || ''
}

export class AppException extends Error {
  private code: string

  constructor (code: string, message: any) {
    const fullMsg = message ? `${code}: ${message}` : code
    super(fullMsg)
    this.name = code
    this.code = code
    this.message = fullMsg
  }

  toString () {
    return this.message
  }
}

export function getLetterGrade (score: number): string {
  if (score >= 99) {
    return 'A+'
  } else if (score >= 97) {
    return 'A'
  } else if (score >= 95) {
    return 'A-'
  } else if (score >= 90) {
    return 'B+'
  } else if (score >= 85) {
    return 'B'
  } else if (score >= 80) {
    return 'B-'
  } else if (score >= 75) {
    return 'C+'
  } else if (score >= 70) {
    return 'C'
  } else if (score >= 65) {
    return 'C-'
  } else if (score >= 60) {
    return 'D+'
  } else if (score >= 55) {
    return 'D'
  } else if (score >= 50) {
    return 'D-'
  } else if (score >= 0) {
    return 'F'
  } else {
    return '-'
  }
}

// deep merge to objeects
export function deepMerge (...objects) {
  // create a new object
  const target = {}

  // deep merge the object into the target object
  const merger = (obj: any) => {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          // if the property is a nested object
          target[prop] = deepMerge(target[prop], obj[prop])
        } else {
          // for regular property
          target[prop] = obj[prop]
        }
      }
    }
  }

  // iterate through all objects and
  // deep merge them with target
  for (let i = 0; i < objects.length; i++) {
    merger(objects[i])
  }

  return target
}

export function convertUUIDForPostgresSchema (uuid: string): string {
  return uuid.replace(/-/g, '_')
}

export function convertPostgresSchemaToUUID (schema: string): string {
  return schema.replace(/_/g, '-')
}

export const employeePricingRanges = [
  { range: '1 - 9 Mitarbeitende', quickcheck: 'Kostenlos', audit: '49 €', monthly: '5 €', yearly: '55 €', low: 1, high: 9 },
  { range: '10 - 24 Mitarbeitende', quickcheck: 'Kostenlos', audit: '49 €', monthly: '10 €', yearly: '110 €', low: 10, high: 24 },
  { range: '25 - 49 Mitarbeitende', quickcheck: 'Kostenlos', audit: '49 €', monthly: '25 €', yearly: '275 €', low: 25, high: 49 },
  { range: '50 - 99 Mitarbeitende', quickcheck: 'Kostenlos', audit: '49 €', monthly: '50 €', yearly: '550 €', low: 50, high: 99 },
  { range: '100 - 199 Mitarbeitende', quickcheck: 'Kostenlos', audit: '49 €', monthly: '75 €', yearly: '825 €', low: 100, high: 199 },
  { range: '200 - 349 Mitarbeitende', quickcheck: 'Kostenlos', audit: '49 €', monthly: '150 €', yearly: '1650 €', low: 200, high: 349 },
  { range: '350 - 499 Mitarbeitende', quickcheck: 'Kostenlos', audit: '49 €', monthly: '225 €', yearly: '2475 €', low: 350, high: 499 },
  { range: '500 - 699 Mitarbeitende', quickcheck: 'Kostenlos', audit: '49 €', monthly: '300 €', yearly: '3300 €', low: 500, high: 699 },
  { range: '700 - 849 Mitarbeitende', quickcheck: 'Kostenlos', audit: '49 €', monthly: '375 €', yearly: '4125 €', low: 700, high: 849 },
  { range: '850 - 1000 Mitarbeitende', quickcheck: 'Kostenlos', audit: '49 €', monthly: '450 €', yearly: '4950 €', low: 850, high: 1000 },
  { range: '1000+ Mitarbeitende', quickcheck: 'Kostenlos', audit: '49 €', monthly: 'auf Anfrage', yearly: 'auf Anfrage', low: 1001, high: 999999 }
] as const
