/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AddClientTeamMember200ResponseInner interface.
 */
export function instanceOfAddClientTeamMember200ResponseInner(value) {
    return true;
}
export function AddClientTeamMember200ResponseInnerFromJSON(json) {
    return AddClientTeamMember200ResponseInnerFromJSONTyped(json, false);
}
export function AddClientTeamMember200ResponseInnerFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'user': json['user'] == null ? undefined : json['user'],
    };
}
export function AddClientTeamMember200ResponseInnerToJSON(json) {
    return AddClientTeamMember200ResponseInnerToJSONTyped(json, false);
}
export function AddClientTeamMember200ResponseInnerToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'user': value['user'],
    };
}
