/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { CreateClientAsAdmin200ResponseFromJSON, CreateClientAsAdminRequestToJSON, } from '../models/index';
/**
 *
 */
export class AdminApi extends runtime.BaseAPI {
    /**
     * Create a new client
     */
    createClientAsAdminRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['createClientAsAdminRequest'] == null) {
                throw new runtime.RequiredError('createClientAsAdminRequest', 'Required parameter "createClientAsAdminRequest" was null or undefined when calling createClientAsAdmin().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/admin/client/new`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateClientAsAdminRequestToJSON(requestParameters['createClientAsAdminRequest']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Create a new client
     */
    createClientAsAdmin(createClientAsAdminRequest, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createClientAsAdminRaw({ createClientAsAdminRequest: createClientAsAdminRequest }, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Rescan all IOEs for this clients
     */
    rescanIoesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['clientUUID'] == null) {
                throw new runtime.RequiredError('clientUUID', 'Required parameter "clientUUID" was null or undefined when calling rescanIoes().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/client/{clientUUID}/audit/rescan`.replace(`{${"clientUUID"}}`, encodeURIComponent(String(requestParameters['clientUUID']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CreateClientAsAdmin200ResponseFromJSON(jsonValue));
        });
    }
    /**
     * Rescan all IOEs for this clients
     */
    rescanIoes(clientUUID, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.rescanIoesRaw({ clientUUID: clientUUID }, initOverrides);
            return yield response.value();
        });
    }
}
