/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SetDatabaseChangeRequestDeliveryInfo interface.
 */
export function instanceOfSetDatabaseChangeRequestDeliveryInfo(value) {
    return true;
}
export function SetDatabaseChangeRequestDeliveryInfoFromJSON(json) {
    return SetDatabaseChangeRequestDeliveryInfoFromJSONTyped(json, false);
}
export function SetDatabaseChangeRequestDeliveryInfoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'currentRetry': json['current_retry'] == null ? undefined : json['current_retry'],
        'maxRetries': json['max_retries'] == null ? undefined : json['max_retries'],
    };
}
export function SetDatabaseChangeRequestDeliveryInfoToJSON(json) {
    return SetDatabaseChangeRequestDeliveryInfoToJSONTyped(json, false);
}
export function SetDatabaseChangeRequestDeliveryInfoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'current_retry': value['currentRetry'],
        'max_retries': value['maxRetries'],
    };
}
