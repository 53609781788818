/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 3.6.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetAllIoes200ResponseIoeInnerSetting interface.
 */
export function instanceOfGetAllIoes200ResponseIoeInnerSetting(value) {
    return true;
}
export function GetAllIoes200ResponseIoeInnerSettingFromJSON(json) {
    return GetAllIoes200ResponseIoeInnerSettingFromJSONTyped(json, false);
}
export function GetAllIoes200ResponseIoeInnerSettingFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function GetAllIoes200ResponseIoeInnerSettingToJSON(json) {
    return GetAllIoes200ResponseIoeInnerSettingToJSONTyped(json, false);
}
export function GetAllIoes200ResponseIoeInnerSettingToJSONTyped(value, ignoreDiscriminator = false) {
    return value;
}
